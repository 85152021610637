import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellness-cafe',
  templateUrl: './wellness-cafe.component.html',
  styleUrls: ['./wellness-cafe.component.css']
})
export class WellnessCafeComponent implements OnInit {

  	hdData: any = [];

  constructor() { 

    let getHDPostsLS = localStorage.getItem('hdPosts');
		if(getHDPostsLS != null){
			this.filterHDPostData(JSON.parse(getHDPostsLS));
		}
    else{
		// get HD posts
	  	this.getHDPosts().then((data) => {
	  		localStorage.setItem('hdPosts', JSON.stringify(data));		
	  		this.filterHDPostData(data);
	  	});
    }
  }

  ngOnInit(): void {
  }

// Filters and formats the HD post data
filterHDPostData(data) {
  console.log('Filtered Data:', data);  // Log the data to ensure the array structure is correct
  
  // Check if data is an array and then proceed
  if (Array.isArray(data)) {
    let filteredData = data.slice(0, 6).map(i => {
      let img = i.descriptorDom?.components?.images || 'default-image-url.jpg'; // Fallback to default image if not found
      let title = i.descriptorDom?.components?.blurbT || 'No Title'; // Ensure fallback value
      let description = i.descriptorDom?.components?.descriptionHtml || 'No Description'; // Ensure fallback value

      // Return formatted data
      return {
        title: title,
        description: this.limitTextToWords(description, 30), // Limit description to 30 words
        image: img,
        link: i.link || '#', // If the link is not found, use a fallback URL
      };
    });
    this.hdData = filteredData;
  } else {
    console.error('Expected an array, but received:', data);
  }
}

  // Function to limit text to a specific number of words
  limitTextToWords(text: string, maxWords: number = 30): string {
    if (!text) return ''; // Return empty string if text is falsy
    const words = text.split(/\s+/); // Split text by whitespace
    const limitedWords = words.slice(0, maxWords); // Limit to first 'maxWords' words
    return limitedWords.join(' ') + (words.length > maxWords ? '...' : ''); // Add '...' if the text is truncated
  }

async getHDPosts() {
  let response = await fetch(`https://healthhub.momentumhealth.co.za/hub/api/crafter/v1/children?crafterSite=mhs-well-blog&crafterUrl=/site/items/blog`);
  let data = await response.json();
  return data;
}

openHDPost(link) {
  window.open(link);
}

}
