import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { GlobalDataService } from '../../shared/global-data';
import { ElearningService } from '../../../app/shared/services/elearning.service';
import { Router } from '@angular/router';
import { trigger } from '@angular/animations';
import { GoogleAnalyticsService } from "../../../app/shared/services/google-analytics-service.service";
import * as CryptoJS from 'crypto-js';


interface AnswerMapper {
  questionID: number;
  topicID: number;
  answer: number;
}

interface SubmitMapper {
  username: string;
  answers: FormArray
}

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {

  questionlist: any ;
  videoID: string;
  topicVid: any;
  eidFormSubmission: FormGroup;
  isLoading: boolean = false;
  assessmentCompleted: boolean = false;
  questionMain: boolean = true;
  questionAnswer: any[];
  questionid: any;
  answerRadioGroup: any;
  questionAnswerChoiceId: any;
  validate: any;
  topicID: string;
  videoId: string;
  videoName: string;
  videoNumber: string;
  videoHeader: string;
  videoUrl: string;
  showQuestionArray: boolean = false;
  assessmentreview: boolean = false;
  assessmentreviewnull: boolean = false;
  score: any;
  totalQuizQuestions: any;
  totalQuizQuestionsCorrect: any;
  topicProgress: any;
  topicmainheadernav: string;
  topicmaindescription: string;
  scoreText: any[];
  showtext: any;
  randomtxt: any;
  

  constructor(private router: Router,
    private globalData: GlobalDataService,
    private fb: FormBuilder,
    private queServ:ElearningService,
    private googleAnalyticsService: GoogleAnalyticsService
    ) { 

      this.eidFormSubmission = this.fb.group({
        questionArray: this.fb.array([])
      });
    }



  ngOnInit(): void {
    this.scoreText = ["Thank you for taking the time to invest in yourself. One small step in your health journey, means one giant step for your well-being. Continue this journey by heading back to the [HOME PAGE], seeing how much you have learnt, and choosing your next challenge" , 
    "Knowing how, or what, to improve starts off by knowing just how much you know! Well done for making the investment in your well-being and for taking steps to be the best YOU. You can keep going on this journey by heading back to the [HOME PAGE], where you can see your progress, and choose your next challenge",
    "Thank you for taking the time to invest in yourself. One small step in your health journey, means one giant step for your well-being. Continue this journey by heading back to the [HOME PAGE], seeing how much you have learnt, and choosing your next challenge"
   ]
   this.showtext = this.scoreText.sort((a, b) => 0.5 - Math.random()); // Randomize text for score screen
   this.randomtxt = this.showtext[0];
      
    $('body').addClass('mini-menu'); // Add mini dashboard menu
    $('.component-content').css("box-shadow", "unset"); //Remove box Shadow from global component
    $('.hp-buttons').css("display", "none"); //Remove HP buttons- from global component
    $(".btn").prop('disabled', true);
    this.getQuestion();
  }


  getQuestion(){
    this.isLoading = true;
    this.queServ.getTopics().subscribe(result => {
      
      if(result !== undefined){ 
        //this.questionlist = result.body.topics
        this.topicmainheadernav = localStorage.getItem("TopicMainHeader");
        this.topicmaindescription = localStorage.getItem("TopicMainDescription");
        
        this.topicID = localStorage.getItem("TopicID");
        this.videoId = localStorage.getItem("VideoID");
        this.videoNumber = localStorage.getItem("Videonumber");
        this.videoName = localStorage.getItem("VideoName");
        this.videoHeader = localStorage.getItem("VideoHeader");
        this.videoUrl = localStorage.getItem("Video Url");
        

        this.questionlist = result.body.topics[Number(this.topicID) - 1].videos[Number(this.videoNumber) -1].questions
        
        console.log(this.questionlist);
        let counter = 0;

        this.questionlist.forEach(question => {
          this.addQuestionController(question.questionID);
          question.questionChoices.forEach(answer => {
            //this.addChoicenController(); 
            answer.selected = false;
          });

          counter ++;
          if(counter == this.questionlist.length){
            this.showQuestionArray = true;
          }
          $(window).scrollTop(0); 
        });
      
      }
    });
    this.isLoading = false;
    $('.component-content').css("box-shadow", "unset"); //Remove box Shadow from global component
  }
  
  submitObj: SubmitMapper;

  get questionArray(){
    return this.eidFormSubmission.controls["questionArray"] as FormArray;
  }

  


  addQuestionController(questionID){
    let vidID = localStorage.getItem("VideoID");
    const questiongrp = this.fb.group({
      questionID:[questionID],
      topicID:[this.topicID],
      videoID:[vidID],
      answer : ['', Validators.required],
    });
    this.questionArray.push(questiongrp);
  }

        
reviewQuestions(){
  if(this.eidFormSubmission.valid == true){
  this.questionArray.value.forEach(response => {
    
    this.questionlist.forEach(question => {
      if(response.questionID == question.questionID){
        question.questionChoices.forEach(answer => {
          if(answer==Number(response.answer)){
            answer.selected = true;
          }
        });
      }
      
    });
    this.eidFormSubmission.value == true;
    this.assessmentreview = true
  });  
 }else{
   $(window).scrollTop(0);
   this.eidFormSubmission.value == false;
   this.assessmentreviewnull = true
 }
  
}
 
  submitQuestion(){

    // console.log("Form", this.questionArray);
    // console.log("list", this.questionlist)

    let username  = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    username = username.toString(CryptoJS.enc.Utf8);
    let scoreTopicID = localStorage.getItem("TopicID");
    
    this.submitObj = ({ username: username, answers: this.questionArray.value});

    if(this.eidFormSubmission.valid == true){

        this.queServ.submitQuestions(this.submitObj).subscribe(result => {
          if(result !== undefined){ 
      
              this.assessmentreview = true // Review Check
              this.questionMain = false; // Set Main screen to off
             
              setTimeout(() => {
               this.queServ.checkTopicProgress().subscribe(score =>{ //Get Questions Score 
                 if (score !=undefined) {
                   let filteredtopic = score.body.topics.filter(p => p.topicID==scoreTopicID);
                   let filterdvid = filteredtopic[0].videos.filter(s => s.videoID==this.videoId);
                
                  this.totalQuizQuestions = filterdvid[0].totalQuizQuestions;
                  this.totalQuizQuestionsCorrect = filterdvid[0].totalQuizQuestionsCorrect;
                  this.topicProgress = filteredtopic[0].topicProgress;

                  // Random Paragrah once use completed the quizz
                  
                 }
               });
              }, 500);
              this.elearningQuizzSubmit()
              this.assessmentCompleted = true;  // Set Completed screen to on
              $(window).scrollTop(0);

          }else{
               this.assessmentreview = false // Review Check
          }
        });
    }else{
      console.log("Null Form valueas");
    }

	}

    nextQuestion(){
      if (this.topicProgress == "100") {
        this.total_topics_completed();
        this.total_self_checks_completed();
        this.router.navigate(['e-learning']);
      } else {
        this.router.navigate(['e-learning']);
       $('.component-content').css("display", "none");
      
        let topicIDQuestion = localStorage.getItem("TopicID");
        this.elearningQuizzNext(); //GA tag Quizz Next Button
        this.total_topics_partially_completed()
        $('.loader').show();
        setTimeout(() => {
          $('.loader').hide();
          $( '#'+topicIDQuestion ).trigger( "click" );
            setTimeout(() => {
              $('.component-content').css("display", "block");
           }, 1000);
        }, 3000);
      
      }
    }

    total_self_checks_completed(){
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Self-Checks Completed',  'Elearning Total Self-Checks Completed',  'Elearning Total Self-Checks Completed');  
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Self-Checks Completed',  'Elearning Total Self-Checks Completed',  'Elearning Total Self-Checks Completed');  
    }

    total_topics_partially_completed(){
      this.topicID = localStorage.getItem("TopicID");
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Topics Partially Completed',  'Elearning Total Topics Partially Completed',  'Elearning Total Topics Partially Completed');  
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Topics Partially Completed',  'Elearning Total Topics Partially Completed',  'Elearning Total Topics Partially Completed');  
    }

    total_topics_completed(){
      this.topicID = localStorage.getItem("TopicID");
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Topics Completed',  'Elearning Total Topics Completed',  'Elearning Total Topics Completed');  
      this.googleAnalyticsService.pushDirectEvent('Elearning Total Topics Completed',  'Elearning Total Topics Completed',  'Elearning Total Topics Completed');  
    }

    elearningQuizzSubmit(){
      this.topicID = localStorage.getItem("TopicID");
      this.googleAnalyticsService.pushDirectEvent('ELearning Self Check - Topic '+this.topicID+' Submit',  'Member MHWwellness ELearning Self Check - Post Login',  'Member MHWwellness ELearning Self Check - Post Login');  
      this.googleAnalyticsService.pushDirectEvent('ELearning Self Check - Topic '+this.topicID+' Submit',  'Member MHWwellness ELearning Self Check - Post Login',  'Member MHWwellness ELearning Self Check - Post Login');  
    }

    elearningQuizzNext(){
      this.topicID = localStorage.getItem("TopicID");
      this.googleAnalyticsService.pushDirectEvent('ELearning Self Check - Topic '+this.topicID+' Next',  'Member MHWwellness ELearning Self Check - Post Login',  'Member MHWwellness ELearning Self Check - Post Login'); 
      this.googleAnalyticsService.pushDirectEvent('ELearning Self Check - Topic '+this.topicID+' Next',  'Member MHWwellness ELearning Self Check - Post Login',  'Member MHWwellness ELearning Self Check - Post Login'); 
    }
   
   
  }



