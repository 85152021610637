import { Component, OnInit } from "@angular/core";
import { GlobalDataService } from "../../shared/global-data";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ElearningService } from "../../../app/shared/services/elearning.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../../app/shared/services/google-analytics-service.service";
import * as CryptoJS from 'crypto-js';

@Component({
  selector: "app-e-learning",
  templateUrl: "./e-learning.component.html",
  styleUrls: ["./e-learning.component.css"],
})
export class ELearningComponent implements OnInit {
  topiclist: any[];
  arraymerge: any[];
  videoID: string;
  topicVid: any;
  videoQuestion: any[];
  videourl: string;
  pdfurl: string;
  mainTopicVideoView: boolean = false;
  mainTopicList: boolean = true;
  mainBanner: boolean = true;
  mainBackButton: boolean = true;
  isLoading: boolean = true;
  progressPercent: string;
  mainvideo: boolean = true;
  progresStat: any;
  customOptions: OwlOptions = {
    items: 1,
    loop: true,
    navSpeed: 700,
    dots: true,
    autoplay: true,
    mouseDrag: true,
  };
  progress: any[];
  vidurl: string;
  videoUrl: string;
  videoProgress: any;
  topicmainheadernav: any;
  topicmaindescription: string;
  topicID: string;
  topicProgress: any;
  surveyProgress: any;
  getTopicID: any;

  isSiteMomWellness = localStorage.getItem("loadMomWellnessStylesheet");
  watchedVideos: any;
  watchedVidesIdArray = [];
  

  constructor(
    private router: Router,
    private globalData: GlobalDataService,
    private queServ: ElearningService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    $("body").addClass("mini-menu"); // Add mini dashboard menu
    $(".component-content").css("box-shadow", "unset"); //Remove box Shadow from global component
    $(".component-content").css("background-color", "transparent");
    this.getTopics();
    this.getWatchedVideo();

  }

  getTopics() {
    this.mainBackButton = false;
    setTimeout(() => {
      this.queServ.getTopics().subscribe((result1) => {
        if (result1 !== undefined) {
          this.topiclist = result1.body.topics;
          this.queServ.checkTopicProgress().subscribe((result2) => {
            if (result2 !== undefined) {
              this.progress = result2.body.topics;

              this.topiclist.forEach((topic) => {
                this.progress.forEach((topicProgress) => {
                  //func call
                  this.setProgress(topic, topicProgress);
                });
              });
            }
          });
          this.isLoading = false;
        }
      });
    }, 1000);
  }

  //watched videos
  getWatchedVideo(){
    let username = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    username = username.toString(CryptoJS.enc.Utf8);
    setTimeout(()=>{
      this.queServ.getWatchedVideos().subscribe(result=>{
        if(result !== undefined){

          if(result.statusCodeValue == 200){
            this.watchedVideos = result.body.watched;
          }else{
            this.watchedVideos = [{
              "videoResponseID":36,
              "watched":true,
              "userName":username,
              "topicID":3000,
              "videoID":2000,
              "createOn": "04 May 2023 10:09:09"
            }];
          }
          
        }
      });
    },1000);
  }

  // setProgressbar for Momwellnes or MHwellness
  setProgress(topic, topicProgress) {
    if (this.isSiteMomWellness) {
      topicProgress.videos.forEach(video=>{
        if(video.videoID == this.videoID){
        video.videoProgress = 100;
          console.log("videoProgress "+ video.videoProgress);
        }
      })
      if (topic.topicID == topicProgress.topicID) {
        let progressStat = 0;

        if (topicProgress.videosWatched.videosWatchedProgress < 1) {
          progressStat = 0;
        } else if (topicProgress.videosWatched.videosWatchedProgress <= 40) {
          progressStat = 25;
        } else if (topicProgress.videosWatched.videosWatchedProgress <= 50) {
          progressStat = 50;
        } else if (topicProgress.videosWatched.videosWatchedProgress <= 75) {
          progressStat = 75;
        } else if (topicProgress.videosWatched.videosWatchedProgress <= 100) {
          progressStat = 100;
        }

        topicProgress.topicProgress = progressStat;
        topic.srcImg =
          "../../../assets/img/icons/elearning/e-learning-icons_trophy-" +
          progressStat +
          ".jpg";
      }
    } else {
      if (topic.topicID == topicProgress.topicID) {
        let progressStat = 0;

        if (topicProgress.topicProgress < 1) {
          progressStat = 0;
        } else if (topicProgress.topicProgress <= 40) {
          progressStat = 25;
        } else if (topicProgress.topicProgress <= 50) {
          progressStat = 50;
        } else if (topicProgress.topicProgress <= 75) {
          progressStat = 75;
        } else if (topicProgress.topicProgress <= 100) {
          progressStat = 100;
        }
        topic.progressStat = progressStat;

        // console.log(topic.progressStat)
        topic.srcImg =
          "../../../assets/img/icons/elearning/e-learning-icons_trophy-" +
          progressStat +
          ".jpg";
      }
    }
  }

  loadview() {
    this.queServ.checkTopicProgress().subscribe((result3) => {
      if (result3 !== undefined) {
        this.progress = result3.body.topics;
        this.getTopicID = localStorage.getItem("TopicID");
        this.topicProgress = this.progress[this.getTopicID - 1].topicProgress;
        if (this.topicProgress == 100) {
          this.elearningSurvey();
          this.router.navigate(["survey"]);
        } else {
          $("#surveyCheck").addClass("score_completed"); // Not allowed
        }
      }
    });
    this.isLoading = false;
  }

  downloadPdf() {
    this.queServ.checkTopicProgress().subscribe((result3) => {
      if (result3 !== undefined) {
        this.progress = result3.body.topics;
        this.getTopicID = localStorage.getItem("TopicID");
        this.topicProgress = this.progress[this.getTopicID - 1].topicProgress;
        this.surveyProgress = this.progress[this.getTopicID - 1].surveyProgress;
        if (this.topicProgress == 25 || this.topicProgress == 33.33) {
          // Check if TopicProgress is 33.3%
          $("a.downloadPrint_completed").removeClass("score_completed"); // Is allowed
        } else if (this.topicProgress == 50 || this.topicProgress == 66.67) {
          // Check if TopicProgress is 66.67%
          $("a.downloadPrint_completed").removeClass("score_completed"); // Is allowed
        } else if (this.topicProgress == 75 || this.topicProgress == 100) {
          // Check if TopicProgress is 100%
          $("a.downloadPrint_completed").removeClass("score_completed"); // Is allowed
        } else {
          $("a.downloadPrint_completed").removeAttr("href");
          $("a.downloadPrint_completed").addClass("score_completed"); // Not allowed
        }
      }
    });
    this.isLoading = false;
  }

  videoview(event, topicmainheader, topicmaindescp) {
    $(window).scrollTop(0);

    if (this.isSiteMomWellness) {
      //GA Topic Number Clicks
      this.elearningTopNumberMomentumwellness();
    } else {
      this.elearningTopNumberMHwellness();
    }
    let topicheader = topicmainheader; //Get Main Header per Topic
    localStorage.setItem("TopicMainHeader", topicheader);
    this.topicmainheadernav = localStorage.getItem("TopicMainHeader");

    let topicdescription = topicmaindescp; // Get Main description per Topic
    localStorage.setItem("TopicMainDescription", topicdescription);
    this.topicmaindescription = localStorage.getItem("TopicMainDescription");

    this.mainBackButton = true; // Show back button
    let topicId = event.target.id;
    console.log("Topic ID", topicId); // Get Topic ID
    localStorage.setItem("TopicID", topicId);
    this.topicID = localStorage.getItem("TopicID");


    this.videoProgress = this.progress[topicId - 1].videos; // Get Video Progress
    this.queServ.getTopics().subscribe((result) => {
      if (result !== undefined) {

        if(this.isSiteMomWellness){
          this.topicVid = result.body.topics[topicId - 1].videos;
          this.watchedVidesIdArray = [];
          this.watchedVideos.forEach(watchedVid=>{
            this.topicVid.forEach(topvid=>{
              if(topvid.videoID == watchedVid.videoID ){
                this.watchedVidesIdArray.push(topvid.videoID);
              }
            });
          });
        }else{
          this.topicVid = result.body.topics[topicId - 1].videos; // Get Video Object
          this.videoProgress.forEach((videoProg) => {
            this.topicVid.forEach((topvid) => {
              if (topvid.videoID == videoProg.videoID) {
                topvid.videoProgress = videoProg.videoProgress;
                topvid.totalQuizQuestionsCorrect =
                  videoProg.totalQuizQuestionsCorrect;
                topvid.totalQuizQuestions = videoProg.totalQuizQuestions;
              }
            });
          });
        }
        
        this.topicVid.forEach((topvid) => {
          console.log(topvid.videoName);
          this.queServ.getVideoUrl(topvid.videoName).subscribe((result) => {
            // Get Video Url
            if (result !== undefined) {
              let videourl = result.url; //Get Main Video Url
              topvid.videoFinalUrl = videourl;
              console.log(topvid.videoFinalUrl);
            }
          });
        });

        this.topicVid.forEach((topvid) => {
          this.queServ.getPdf(topvid.knowledgePDFName).subscribe((result) => {
            // Get Video Url
            if (result !== undefined) {
              let pdfurl = result.url; //Get Main Video Url
              topvid.videoPdfUrl = pdfurl;
              let text = topvid.knowledgePDFName;
              let resultext = text.replace("_v1.pdf", "");
              topvid.knowledgePDFName = resultext;
              console.log(topvid.knowledgePDFName);
            }
          });
        });

        this.mainTopicVideoView = true;
        this.mainBanner = false;
        this.mainTopicList = false;
      }
    });


    if (this.isSiteMomWellness) {
      this.elearningTopicMainMomentumwellness();
    } else {
      this.elearningTopicMainMHwellness();
    }
    $("html, body").animate({ scrollTop: $(".mainTopicVideoViewop").offset() });
    setTimeout(() => {
      this.downloadPdf();
    }, 500);
  }

  mainVideo(videoIdparam, videonameparam, videonumberparam, videoheaderparam) {
    let videoId = videoIdparam;
    let videoname = videonameparam;
    let videonumber = videonumberparam;
    let videoHeader = videoheaderparam;

    console.log("Video ID:", videoId);
    console.log("Video Name:", videoname);
    console.log("Video Header:", videoHeader);

    localStorage.setItem("VideoID", videoId);
    localStorage.setItem("Videonumber", videonumber);
    localStorage.setItem("VideoName", videoname);
    localStorage.setItem("VideoHeader", videoHeader);
    let videmax = videoname;

    this.queServ.getVideoUrl(videmax).subscribe((result) => {
      if (result !== undefined) {
        let videourl = result.url; //Get Main Video Url
        console.log(videourl);
        localStorage.setItem("Video Url", videourl);
        this.vidurl = localStorage.getItem("Video Url");
      }
    });

    this.mainTopicVideoView = true;
    this.mainBanner = false;
    this.mainTopicList = false;
    this.router.navigate(["video"]);
  }

  elearningHome() {
    this.mainBanner = true;
    this.mainTopicList = true;
    this.mainTopicVideoView = false;
    this.mainBackButton = false;
  }

  //Google analytics for MH wellness
  elearningTopicMainMHwellness() {
    this.googleAnalyticsService.pushDirectEvent("Elearning Dashboard Click", 
       "Member MHWwellness ELearning Dashboard - Post Login",
     "Member MHWwellness Elearning Dashboard - Post Login",
    );
    this.googleAnalyticsService.pushDirectEvent("Elearning Dashboard Click", 
    "Member MHWwellness ELearning Dashboard - Post Login",
  "Member MHWwellness Elearning Dashboard - Post Login",
 );
  }
  //Google analytics for Momentum wellness
  elearningTopicMainMomentumwellness() {
    this.googleAnalyticsService.pushDirectEvent( 
      "Elearning Dashboard Click", 
      "Member MomentumWellness ELearning Dashboard - Post Login",
      "Member MomentumWellness Elearning Dashboard - Post Login",
    );
    this.googleAnalyticsService.pushDirectEvent( 
      "Elearning Dashboard Click", 
      "Member MomentumWellness ELearning Dashboard - Post Login",
      "Member MomentumWellness Elearning Dashboard - Post Login",
    );
  }

  //Google analytics for MH wellness
  elearningTopNumberMHwellness() {
    this.topicID = localStorage.getItem("TopicID");
    this.topicmainheadernav = localStorage.getItem("TopicMainHeader");
    this.googleAnalyticsService.pushDirectEvent(
     
      "Elearning Topic " +
      this.topicID +
      " " +
      this.topicmainheadernav +
      " Click",
      "Member MHWwellness ELearning Topic - Post Login",
       "Member MHWwellness Elearning - Post Login",
      
    );
    this.googleAnalyticsService.pushDirectEvent(
     
      "Elearning Topic " +
      this.topicID +
      " " +
      this.topicmainheadernav +
      " Click",
      "Member MHWwellness ELearning Topic - Post Login",
       "Member MHWwellness Elearning - Post Login",
      
    );
  }
  //Google analytics for Momentum wellness
  elearningTopNumberMomentumwellness() {
    this.topicID = localStorage.getItem("TopicID");
    this.topicmainheadernav = localStorage.getItem("TopicMainHeader");
    this.googleAnalyticsService.pushDirectEvent(
     
      "Elearning Topic " +
      this.topicID +
      " " +
      this.topicmainheadernav +
      " Click",
    "Member MomentumWellness ELearning Topic - Post Login",
       "Member MomentumWellness Elearning - Post Login",
      
    );
    this.googleAnalyticsService.pushDirectEvent(
     
      "Elearning Topic " +
      this.topicID +
      " " +
      this.topicmainheadernav +
      " Click",
    "Member MomentumWellness ELearning Topic - Post Login",
       "Member MomentumWellness Elearning - Post Login",
      
    );
  }

  elearningSurvey() {
    this.topicID = localStorage.getItem("TopicID");
    this.googleAnalyticsService.pushDirectEvent(
     
      "ELearning Wellness Window Check  - Topic " + this.topicID + " Click",
      "Member MHWwellness ELearning Wellness Window Check  - Post Login",
      "Member MHWwellness ELearning Wellness Window Check  - Post Login",
      
    );
    this.googleAnalyticsService.pushDirectEvent(
     
      "ELearning Wellness Window Check  - Topic " + this.topicID + " Click",
      "Member MHWwellness ELearning Wellness Window Check  - Post Login",
      "Member MHWwellness ELearning Wellness Window Check  - Post Login",
      
    );
  }

  total_PDF_downloaded() {
    this.googleAnalyticsService.pushDirectEvent('Elearning Total PDF Downloaded','Elearning Total PDF Downloaded','Elearning Total PDF Downloaded');
    this.googleAnalyticsService.pushDirectEvent('Elearning Total PDF Downloaded','Elearning Total PDF Downloaded','Elearning Total PDF Downloaded');
  }
}
