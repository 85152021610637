import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PASSWORD_REGEX } from '../../../shared/utilities';
import { SecurityService } from '../../../shared/services/security.service';
import { ReghellodoctorService } from '../../../shared/services/reghellodoctor.service';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _util from '../../../shared/utilities';
import * as CryptoJS from 'crypto-js';
import { GlobalDataService } from 'src/app/shared/global-data';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {

	passwordForm: FormGroup;
	isLoading: boolean = false;
	hideForm: boolean = true;
	userExists: boolean = true;
	userValidation: boolean =true;
	isInvalidUrl: boolean;
	tokenExpired: boolean;
	showPasswordHints: boolean = true;
	passwordsMatch: boolean;
	currStep: number;
	username: string;
	disableSubmit : boolean = false;
	isPassHintsOpen: boolean = false;
	passMatchTxt: string = '';
	ctx_name: string;
	ctx_person: string;
	errorMsg: string = '';
	global_userID: any;
	global_personID: any;

	constructor(private fb: FormBuilder, private securityServ: SecurityService, private hdService: ReghellodoctorService, private globalData: GlobalDataService) {}

	ngOnInit(): void {
		let locSearch = location.search;
		if(locSearch.indexOf('token=') <= -1){
			this.isInvalidUrl = true;
		} else {
			let sanitizeToken = locSearch.replace("?token=","");
			this.securityServ.decryptToken(sanitizeToken).subscribe(resp => {

				if(resp.momentumHealth.responseStatus.indexOf('expired') > -1){
					this.tokenExpired = true;
				} else if(resp.momentumHealth.optionalParameters != null){
					this.hideForm = false;
					this.currStep = 1;
					this.isInvalidUrl = false;
					this.username = resp.momentumHealth.optionalParameters.username;
					
					/* ------------------ BASE FORM ------------------- */
					this.passwordForm = this.fb.group({
						password_one: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
						password_two: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]]
					});
					//this.checkPasswords();
					this.passwordForm.statusChanges.subscribe(s => {
						let p1Field = this.passwordForm.get('password_one');
						let p2Field = this.passwordForm.get('password_two');
						if((p1Field.dirty && p1Field.value.length < 8) || 
							(p2Field.dirty && p2Field.value.length < 8)){
							this.passMatchTxt = 'Please enter a password with 8 characters or more.';
						} else if((p1Field.dirty && p2Field.dirty) && p1Field.value != p2Field.value){
							this.passMatchTxt = 'Your password does not match.';
							this.disableSubmit = false;
						} else if(s == 'VALID') {
							this.passMatchTxt = '';
							this.disableSubmit = true;
						}
					});
				} else {
					this.isInvalidUrl = true;
				}
			});
		}
		$('body').addClass('logged-out-view');
	}

	checkPasswords(){
		this.passwordForm.get('password_one').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val.length < 8){
				this.passMatchTxt = 'Please enter a password with 8 characters or more.';
				this.passwordForm.get('password_one').setErrors({'incorrect': true});
			} else {
				this.showPasswordHints = true;
				this.disableSubmit = false;
			}
		});
		this.passwordForm.get('password_two').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val.length < 8){
				this.passMatchTxt = 'Please enter a password with 8 characters or more.';
				this.passwordForm.get('password_two').setErrors({'incorrect': true});
			} else {
				this.showPasswordHints = true;
				this.disableSubmit = false;
			}
		});
	}

	hellodoctorRegistration(){
		
	}
	setPassword(){
		
		this.isLoading = true;
		this.securityServ.getUserData(this.username).subscribe(resp => {
			if(resp.statusCodeValue == 200){
				if(resp.body[0].username == this.username){
					this.userExists = true;
					let getInputPassword = this.passwordForm.get('password_one').value;
					localStorage.setItem("HDregister", getInputPassword );

					let authObj ={
						username: resp.body[0].emailAddress,
					    password: this.passwordForm.get('password_one').value,
						rememberMe: true
					}

					this.hdService.authenticate(authObj).subscribe(respauth => {
					 let token = respauth.id_token
					 localStorage.setItem("hdregtoken", token)
					})
					
					var id = CryptoJS.AES.decrypt(localStorage.getItem("userIdNumber"), this.globalData.secretKey).toString(CryptoJS.enc.Utf8);
					let idnumber = id.toString(CryptoJS.enc.Utf8);

					//register HD 
					let verifyObj = {
						entityID: "127",
						idNumber: idnumber,
						mobileNumber: localStorage.getItem("ContactNumber")
					}
				
					setTimeout(() => {
						this.hdService.verify(verifyObj).subscribe(resp => {				
							if(resp.statusCode == "102"){ // user exists...create callback
								console.log("HelloDr Name Exist")
							} else if(resp.statusCode == "103"){ // user doesnt exist...auto reg
								let regObj = {
									entityID: verifyObj.entityID,
									idNumber: verifyObj.idNumber,
									mobileNumber: verifyObj.mobileNumber,
									optionCode: "32134",
									name: localStorage.getItem("hdname"),
									surname: localStorage.getItem("hssurname"),
								}
								this.hdService.registerHDUser(regObj).subscribe(regResp => {
									if(regResp.statusCode == "300"){ // unsuccessfull
										this.isLoading = false;
										this.errorMsg = `We could not register your profile due to a technical error. 
											Please contact us on +27 (0) 87 230 0002.`;
									} else if(regResp.statusCode == "200"){ // succesfull
										this.global_userID = regResp.userID;
										this.global_personID = regResp.personID;
									
									}
								}, err => {
									this.errorMsg = `We could not log your call. Please contact us on +27 (0) 87 230 0002.`;
									this.isLoading = false;						
								})
							}
						}, err => {
							this.isLoading = false;
							this.errorMsg = 'We could not log your call. Please contact us on +27 (0) 87 230 0002.';
						});
					}, 800);
					
			
					let getGuid = resp.body[0].uniqueId;
					let passwordRequestModel ={
						uniqueId: getGuid,
						newPassword: getInputPassword
					}

					this.securityServ.setNewPassword(passwordRequestModel).subscribe(innerResp => {
						if(innerResp.statusCodeValue == 200){
							this.isLoading = false;
							this.currStep = 2;
						}
					}, err => {
						//this.userExists = false;
						this.userValidation = false;
						this.isLoading = false;
					});
				} else {
					this.userExists = false;
					this.isLoading = false;		
				}
			} else if(resp.statusCodeValue == 404){
				this.userExists = false;
				this.isLoading = false;
			}
		}, err => {
			this.isLoading = false;
		});

		

	
	}
}
