import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/security/login/login.component';
import { RegisterComponent } from './components/security/register/register.component';
import { ForgotpassComponent } from './components/security/forgotpass/forgotpass.component';
import { LeftmenuComponent } from './components/shared/leftmenu/leftmenu.component';
import { TopblocksComponent } from './components/shared/topblocks/topblocks.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { CaseComponent } from './components/shared/case/case.component';
import { FormComponent } from './components/shared/case/form/form.component';
import { TableComponent } from './components/shared/case/table/table.component';
import { CalendarComponent } from './components/shared/case/calendar/calendar.component';
import { SetpasswordComponent } from './components/security/setpassword/setpassword.component';


import { 
  MatDatepickerModule, 
  MatNativeDateModule, 
  MatGridListModule
} from '@angular/material';

/* 3rd party vendor */
import { CarouselModule } from 'ngx-owl-carousel-o';

/* forms */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* router */
import { RouterModule, Routes } from '@angular/router';
import { ModalComponent } from './components/shared/modal/modal.component';
import { LoadingComponent } from './components/shared/loading/loading.component';

// custom services
import { AuthenticationInterceptor } from './shared/services/interceptor-service';
import { GlobalDataService } from './shared/global-data';
import { SecurityService } from './shared/services/security.service';
import { QuestionnaireService } from './shared/services/questionnare.service';
import { CasesService } from './shared/services/cases.service';
import { SynergyService } from './shared/services/synergy.service';
import { HelloDoctorService } from './shared/services/hellodoctor.service';
import { ReportsService } from './shared/services/reports.service';

/* Custom Components */
import { ReportComponent } from './components/report/report.component';
import { LegalServicesComponent } from './components/legal-services/legal-services.component';
import { FinancialWellnessComponent } from './components/financial-wellness/financial-wellness.component';
import { WellBeingComponent } from './well-being/well-being.component';
import { WellnessCafeComponent } from './wellness-cafe/wellness-cafe.component';
import { SuggestedResourceComponent } from './suggested-resource/suggested-resource.component';
import { WellbeingScoreComponent } from './wellbeing-score/wellbeing-score.component';
import { ImproveHealthComponent } from './improve-health/improve-health.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { ReportsDashboardComponent } from './components/reports-dashboard/reports-dashboard.component';
import { CofsComponent } from './components/cofs/cofs.component';
import { SafepipePipe } from './shared/safepipe.pipe';
import { ELearningComponent } from './components/e-learning/e-learning.component';
import { SurveyComponent } from './survey/survey.component';
import { VideoComponent } from './video/video.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { WellbeingHubComponent } from './wellbeing-hub/wellbeing-hub.component';
import { ThirdPartyService } from './shared/services/third-party.service';
import { SanitizeHtmlPipe } from './shared/sanitize-html.pipe';

/* routes */

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'renewpass', component: ForgotpassComponent },
  { path: 'renewpass/:id', component: ForgotpassComponent },
  { path: 'setpassword', component: SetpasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'case', component: CaseComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'report', component: ReportComponent },
  { path: 'legal-services', component: LegalServicesComponent },
  { path: 'financial-wellness', component: FinancialWellnessComponent },
  { path: 'well-being', component: WellBeingComponent },
  { path: 'wellness-cafe', component: WellnessCafeComponent },
  { path: 'suggested-resource', component: SuggestedResourceComponent },
  { path: 'well-being-score', component: WellbeingScoreComponent },
  { path: 'improve-health', component: ImproveHealthComponent },
  { path: 'questionnaire', component: QuestionnaireComponent },
  { path: 'reports-dashboard', component: ReportsDashboardComponent },
  { path: 'cofs', component: CofsComponent },
  { path: 'e-learning', component: ELearningComponent },
  { path: 'survey', component: SurveyComponent },
  { path: 'cofs', component: CofsComponent },
  { path: 'video', component: VideoComponent },
  { path: 'assessment', component: AssessmentComponent },
  { path: 'wellbeing-hub', component: WellbeingHubComponent },
  
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotpassComponent,
    LeftmenuComponent,
    TopblocksComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    FaqsComponent,
    CaseComponent,
    FormComponent,
    TableComponent,
    CalendarComponent,
    ModalComponent,
    LoadingComponent,
    SetpasswordComponent,
    ReportComponent,
    LegalServicesComponent,
    FinancialWellnessComponent,
    WellBeingComponent,
    WellnessCafeComponent,
    SuggestedResourceComponent,
    WellbeingScoreComponent,
    ImproveHealthComponent,
    QuestionnaireComponent,
    ReportsDashboardComponent,
    CofsComponent,
    SafepipePipe,
    ELearningComponent,
    SurveyComponent,
    VideoComponent,
    AssessmentComponent,
    WellbeingHubComponent,
    SanitizeHtmlPipe
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    CarouselModule,
    BrowserModule,
    MatNativeDateModule,
    MatDatepickerModule, 
    MatGridListModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true
  }, GlobalDataService, SecurityService, CasesService, SynergyService, HelloDoctorService, ReportsService, ThirdPartyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
