import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../shared/global-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { QuestionnaireService } from '../shared/services/questionnare.service';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-improve-health',
    templateUrl: './improve-health.component.html',
    styleUrls: ['./improve-health.component.css']
})
export class ImproveHealthComponent implements OnInit {

    score:string = "65";
    pdfURL: string;
    Paragraph:string ="";
    point1:string = "";
    point2:string = "";
    point3:string = "";
    point4:string = "";
    point5:string = "";
    isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');
    contentServer_MH = `https://content.momentum.co.za/EAP`;
    contentServer_MOM = `https://content.momentum.co.za/mmsa/complete_health/pdfs/Wellness/Reports`;

    constructor(private router: Router, private globalData: GlobalDataService, private queServ:QuestionnaireService,
        private lsService: StorageMap) {
    }

    ngOnInit(): void {
        this.getScore();
    }

    goToPage(){
        this.router.navigate(['well-being-score']);
    }

    viewReport(){
        this.router.navigate(['suggested-resource']);
    }

    diabeteslink(){
        if(this.isSiteMomWellness){
            window.open(`${this.contentServer_MOM}/MW_Diabetes_Report.pdf`);
        } else { // MH
            window.open(`${this.contentServer_MH}/Suggested%20Diabetes%20Program.pdf`);
        }
    }

    downloadReport(){
        if(this.isSiteMomWellness){
            window.open(`${this.contentServer_MOM}/MW_Cardiovascular_Report.pdf`);
        } else { // MH
            window.open(`${this.contentServer_MH}/Suggested%20Cardiovascular%20Disease%20Program.pdf`);
        }
    }

    getScore() {
        var id = CryptoJS.AES.decrypt(localStorage.getItem("userIdNumber"), this.globalData.secretKey).toString(CryptoJS.enc.Utf8);
		let idnumber = id.toString(CryptoJS.enc.Utf8);
        this.queServ.getScoreQuestionnaire(idnumber).subscribe(result => {
            if (result !== undefined) {
                this.score = result[0].score;
                if (this.score <= "20") {
                    $('.status-btn-red').hide();
                    $('.status-btn-green').show();
                    $('.status-btn-amber').hide();
                    this.Paragraph = "Thanks for taking an interest in your health...that's the first step in moving towards a healthier you! Your score is green which suggests that you have a pretty good handle on what you need to do to stay healthy, excellent news! A proactive approach to wellbeing will help you achieve optimal health and wellness, and avoid many risks of illness and injury in the future."
                    this.point1 = "Download your Free Lifestyle & Stress Support Report to sustain optimal health. Find ways to prevent and relieve stress when needed." 

                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(GREEN)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Green-Report.pdf`;
                    }
                    
                    $('.dandc').hide();

                } else if (this.score <= "45") {
                    $('.status-btn-red').hide();
                    $('.status-btn-green').hide();
                    $('.status-btn-amber').show();
                    this.Paragraph= "Thanks for taking an interest in your health...that's the first step in moving towards a healthier you! Your score is amber which suggests that there is some work to do on your current lifestyle. Fortunately, this is a good place to start! A proactive approach to wellbeing will help you achieve optimal health and wellness, and avoid many risks of illness and injury in the future.";
                    this.point1= "Download your Free Lifestyle & Stress Support Report and find ways to prevent and relieve stress and learn about lifestyle changes that can improve your physical and mental health.";
                    this.point2 ="Call your counselling support line for assistance (If stress score is medium or high risk).";
                    this.point3=" Download your Free Diabetes Healthy Lifestyle Programme Align the “Download PDF” button (If blood sugar results in health and activity survey is higher than normal and/or self-reported medical history of diabetes).";
                    this.point4="Download your Free Healthy Heart Lifestyle Programme Align the “Download PDF” button (If blood pressure and/or cholesterol results is higher than normal and/or self-reported medical history of cardiac).";
                    
                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(AMBER)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Amber-Report.pdf`;
                    }

                    $('.amberr').hide();
                } else {
                    $('.status-btn-red').show();
                    $('.status-btn-amber').hide();
                    $('.status-btn-green').hide();
                    this.Paragraph = "Thanks for taking an interest in your health... that's the first step in moving towards a healthier you! Your score is red. It's important to start understanding what a healthy lifestyle is and what changes you can make to improve yours. Fortunately, this is a good place to start! A proactive approach to managing your health will avoid many other associated health risks and will help you achieve optimal health and wellness in the future."
                    this.point1 ="Download your Free Lifestyle & Stress Support Report to improve your health and find ways to prevent and relieve stress.";
                    this.point2 = "See your GP shortly for a health check unless you have done so recently (If lifestyle score is high risk).";
                    this.point3 = "Call your counselling support line for assistance (If stress score is medium or high risk).";
                    this.point4 = " Download your Free Diabetes Healthy Lifestyle Programme (If blood sugar results is higher than normal in the health and activity survey and/or self-reported medical history of diabetes) Align the 'Download PDF' button.";
                    this.point5 = " Download your Free Healthy Heart Lifestyle Programme (If blood pressure and/or cholesterol results is higher than normal in the health and activity survey and/or self-reported medical history of high cardiac) Align the 'Download PDF' button.";

                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(RED)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Red-Report.pdf`;
                    }
                }
            }
        });
    }
}
