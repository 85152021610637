import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../global-data';
import * as Enums from '../enums';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })

export class ElearningService {
	httpOptions: any = {};
	mainToken: any;

    
    constructor(public http: HttpClient, public globalData: GlobalDataService) {
	}
    
    getTopics (): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_TOPICS_ELEARNING, this.httpOptions).pipe(
			tap(data => console.log("Elearning done")),
                catchError(this.handleError('Elearning Topics: Get Topic,Questions,Survey,Video', 'undefined'))
		);
	}	

	getVideoUrl (vidmax): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");
		let videname = vidmax

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_VIDEO_URL_ELEARNING+"?filename="+videname, this.httpOptions).pipe(
			tap(data => console.log("Get Video Url done")),
                catchError(this.handleError('Elearning Topics: Get Topic,Questions,Survey,Video', 'undefined'))
		);
	}	

	

	getPdf (vidmax): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");
		let videname = vidmax

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_PDF_URL_ELEARNING+"?filename="+videname, this.httpOptions).pipe(
			tap(data => console.log("Get PDF Url done")),
                catchError(this.handleError('Elearning Topics: Get Topic,Questions,Survey,Video', 'undefined'))
		);
	}	
	
	checkTopicProgress (): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");
		let username = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    	username = username.toString(CryptoJS.enc.Utf8);

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	

		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.CHECK_TOPIC_PROGRESS+'?username='+username, this.httpOptions).pipe(
			tap(data => console.log("Checking Topic Progress - Elearning")),
                catchError(this.handleError('Elearning Topics: Check Progress', 'undefined'))
		);
	}	

	submitQuestions (submitObj): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");
		let username = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    	username = username.toString(CryptoJS.enc.Utf8);

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}
         console.log(submitObj);
		
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.POST_QUESTION_ELEARNING, JSON.stringify(submitObj), this.httpOptions).pipe(
			tap(data => console.log("QUESTION: submit elearning")),
            catchError(this.handleError('QUESTIONNAIRE: submit questionnaire', 'undefined'))
		);
    }

	//submit watched video
	submitVideo (submitObj): Observable<any>{
		let bear = localStorage.getItem("grepsislwr29");

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}
        console.log(submitObj);
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.POST_WATCHED_VIDEO,JSON.stringify(submitObj),this.httpOptions).pipe(
			tap(data =>console.log("VIDEO WATCHED: submit video")),
			catchError(this.handleError('VIDEOS: submit video','undefined'))
		);
	}

	//GET watched videos

	getWatchedVideos():Observable<any>{

		let bear = localStorage.getItem("grepsislwr29");
		let username = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    	username = username.toString(CryptoJS.enc.Utf8);

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		};

		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_WATCHED_VIDEOS+'?username='+username, this.httpOptions).pipe(
			tap(data => console.log("Get watched videos done")),
                catchError(this.handleError('Watched videos', 'undefined'))
		);


	}

	saveSurvey (submitObj): Observable<any> {
		let bear = localStorage.getItem("grepsislwr29");
		let username = CryptoJS.AES.decrypt(localStorage.getItem("username"), this.globalData.secretKey);
    	username = username.toString(CryptoJS.enc.Utf8);

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}
         console.log(submitObj);
		
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.SAVE_SURVEY, JSON.stringify(submitObj), this.httpOptions).pipe(
			tap(data => console.log("QUESTION: submit survey")),
            catchError(this.handleError('QUESTIONNAIRE: submit questionnaire', 'undefined'))
		);
    }

    

    private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
    
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}

}