import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GlobalDataService } from '../../../shared/global-data';
import { HelloDoctorService } from '../../../shared/services/hellodoctor.service';
import { SynergyService } from '../../../shared/services/synergy.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _util from '../../../shared/utilities';
declare var gtag

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

	isModalActive: boolean = false;
	isFinancialModalActive: boolean = false;
	isRequestCallBack: boolean = true;
	isFinancialToolsModalActive: boolean = false;
	isLegal: boolean = false;
	isLegalToolsModalActive: boolean = false;
	isSuccessfull: boolean = false;
	showServBlock: boolean = false;
	showCallbackDetail: boolean = false;
	isEmergency: boolean = false;
	isFinancial: boolean = false;
	isFinancialTools: boolean = false;
	errorMsg: string = '';
	showIdNumber: boolean = true;
	isLoading: boolean = false;
	ctx_name: string = '';
	eap_ref_no: string = '';
	ctx_person: string = '';
	global_name: string;
	global_surname: string;
	global_email: string;
	global_userID: string;
	global_personID: string;
	global_guid1: string;
	global_guid2: string;
	type: string = '';
	callbackForm: FormGroup;
	isNedBank:boolean=false;

	constructor(private globalData: GlobalDataService, 
		private fb: FormBuilder,
		private hdService: HelloDoctorService,
		private synServ: SynergyService, 
		private lsService: StorageMap) { }

	ngOnInit() {

		this.callbackForm = this.fb.group({
			idNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13)]],
			contactNo: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)]]
		});		

		this.globalData.tBlockStep.subscribe(val => {
			if(val == 4){
				this.setModalContent('emergency');
			}
		});

        /* lets get profile data */
        this.globalData.profileData.subscribe(gsData => {
            this.lsService.get('loginData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
					this.global_name = lsData["firstname"];
					this.global_surname = lsData["surname"];
					this.global_email = lsData["emailAddress"];               	
                } else {
                    if(gsData != null){
						this.global_name = gsData["firstname"];
						this.global_surname = gsData["surname"];
						this.global_email = gsData["emailAddress"];						
                    }
                }
            });
			this.lsService.get('profileData').subscribe((lsData) => {
				if(lsData !=undefined)
				this.isNedBank = lsData["isNedBank"]
			})
        });

        /* lets get synergy data */
        this.globalData.synergyData.subscribe(gsData => {
            this.lsService.get('synergyData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
					this.global_guid1 = lsData['getGuid1'];
					this.global_guid2 = lsData['getGuid2'];               	
                } else {
                    if(gsData != null){
						this.global_guid1 = gsData['getGuid1'];
						this.global_guid2 = gsData['getGuid2'];						
                    }                    
                }
            });
        });

		// catch global modal state
		this.globalData.currModalType.subscribe(type => {
			if(type != null){
				if(type == 'callback'){
					this.isRequestCallBack = true;
					this.isModalActive = true;
					this.showServBlock = true;
					this.isFinancial = false;
					this.isFinancialToolsModalActive = false;
					this.isFinancialTools = false;
					this.isLegal = false;
					this.isLegalToolsModalActive = false;
				} else if(type == 'financial_calc'){
					this.isFinancialModalActive = true;
					this.showServBlock = false;
					this.isModalActive = true;
					this.showCallbackDetail = false;
					this.isFinancial = true;
					this.isRequestCallBack = false;
					this.isFinancialToolsModalActive = false;
					this.isFinancialTools = false;
					this.isLegal = false;
					this.isLegalToolsModalActive = false;
				} else if(type == 'financial_tools'){
					this.showServBlock = false;
					this.isModalActive = true;
					this.showCallbackDetail = false;
					this.isFinancial = false;
					this.isRequestCallBack = false;
					this.isFinancialToolsModalActive = true;
					this.isFinancialTools = true;
					this.isLegal = false;
					this.isLegalToolsModalActive = false;
				} else if(type == 'legal_tools'){
					this.showServBlock = false;
					this.isModalActive = true;
					this.showCallbackDetail = false;
					this.isFinancial = false;
					this.isRequestCallBack = false;
					this.isFinancialToolsModalActive = false;
					this.isLegal = true;
					this.isFinancial = false;
					this.isLegalToolsModalActive = true;
					this.isFinancialTools = false;
				}  else {
					this.showServBlock = false;
					this.showCallbackDetail = true;
					this.isLegalToolsModalActive = false;
					this.setModalContent(type);
				}
			}
		})

		this.checkID();

	} /* end ngOnInit */

	checkID(){
		this.callbackForm.get('idNumber').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(typeof val != "undefined" && val != null){
				if(val.length >= 13){
					if(!_util.verifyAuthId(val)){
						this.callbackForm.controls['idNumber'].setErrors({'incorrect': true});
						this.errorMsg = `Please enter a valid ID number`;
					} else {
						this.errorMsg = '';
						this.callbackForm.controls['idNumber'].setErrors(null);
					}
				}
			}
		});
	}

	setModalContent = function(context){
		this.isModalActive = true;
		setTimeout(() => {
			// set modal position
			let modalContainer = $(".modal");
			let getTopPos = $(document).scrollTop() / 1.1;
			modalContainer.css({ top: getTopPos+"px" });
			if(context == 'help'){
				this.type = 'eap';
				this.isFinancial = false;
				this.isFinancialModalActive = false;
				this.isRequestCallBack = true;
				this.isLegal = false;
				this.isLegalToolsModalActive = false;
				this.isFinancialToolsModalActive = false;
				this.isFinancialTools = false;
				this.handleIdNumber(false);
			} else if(context == 'emergency'){
				this.showServBlock = false;
				this.showCallbackDetail = true;
				this.isFinancialModalActive = false;
				this.isEmergency = true;
				this.isRequestCallBack = true;
				this.type = 'eap';
				this.handleIdNumber(false);
				this.ctx_name = 'EAP';
				this.ctx_person = 'call center agent';
			}
		
		}, 400);
	}

	handleIdNumber(isActive){
		if(isActive){
			this.showIdNumber = true;
			this.callbackForm.get('idNumber').setValidators([Validators.required, 
				Validators.pattern('^[0-9]*$'),Validators.minLength(13)]);
			this.callbackForm.get('idNumber').updateValueAndValidity();			
		} else {
			this.showIdNumber = false;
			this.callbackForm.get('idNumber').clearValidators();
			this.callbackForm.get('idNumber').updateValueAndValidity();			
		}
	}	

	closeModal(){
		this.callbackForm.reset();
		this.isSuccessfull = false;
		this.isModalActive = false;
		this.isFinancialModalActive = false;
		this.showCallbackDetail = false;
		this.errorMsg = '';
		this.eap_ref_no = '';
		this.isEmergency = false;
	}

	setCallType(type){
		this.showServBlock = false;
		this.showCallbackDetail = true;
		if(type == 'eap'){
			this.type = 'eap';
			this.handleIdNumber(false);	
			gtag('event', 'Number of Hello Doctor call back click thru’s', {'event_category': 'Number of Hello Doctor call back click thru’s', 'event_label': 'Number of Hello Doctor call back click thru’s'});		
		}
		if(type == 'hd'){
			this.type = 'hd';
			this.handleIdNumber(true);
			gtag('event', 'Number of EAP counsellor click thru’s', {'event_category': 'Number of EAP counsellor click thru’s', 'event_label': 'Number of EAP counsellor click thru’s'});		

		}
	}

	initHDCallBackAPI(inputObj,apiRespObj){
		let createReqObj = {
			entityID: inputObj.entityID,
			mobileNumber: inputObj.mobileNumber,
			personID: apiRespObj.personID,
			userID: apiRespObj.userID
		}
		this.hdService.createHDCallback(createReqObj).subscribe(createCBResp => {
			this.isLoading = false;
			if(createCBResp.isSuccessful == 'true'){
				this.showCallbackDetail = false;
				this.isSuccessfull = true;
				this.ctx_name = "Hello Doctor";
				this.ctx_person = "doctor";
			} else {
				this.errorMsg = 'We could not log your call. Please contact us on +27 (0) 87 230 0002.';
			}
		}, err => {
			this.isLoading = false;
			this.errorMsg = 'We could not log your call. Please contact us on +27 (0) 87 230 0002.';
		});
	}

	requestCallBack(){
		this.errorMsg = '';
		this.eap_ref_no = '';
		this.isLoading = true;
		// EAP Counsellor API calls
		if(this.type == 'eap'){
			let counsellorObj = {
				contactNumber: this.callbackForm.get('contactNo').value,
				email: this.global_email,
				companyCodeDebtorAccountID: this.global_guid2,
				employeeGUID: this.global_guid1,
				name: this.global_name,
				surname: this.global_surname
			}
			if(this.isEmergency){
				counsellorObj["description"] = "Emergency";
				counsellorObj["synergyRequestType"] = "266";
			} else {
				counsellorObj["description"] = "EAP Counsellor";
				counsellorObj["synergyRequestType"] = "210";
			}
			this.synServ.createCounsCallback(counsellorObj).subscribe(counsResp => {
				this.isLoading = false;
				if(counsResp.statusCodeValue == 200){
					let refNo = counsResp.body.RequestNumber;
					this.showCallbackDetail = false;
					this.isSuccessfull = true;
					this.ctx_name = "EAP";
					this.ctx_person = "call center agent";
					this.eap_ref_no = `${refNo}`;
				}
			}, err => {
				this.errorMsg = `We could not log your call. Please contact your EAP contact centre.`;
				this.isLoading = false;
			});
		}
		// Hello Doctor API calls
		if(this.type == 'hd'){
			let verifyObj = {
				entityID: "138",
				idNumber: this.callbackForm.get('idNumber').value,
				mobileNumber: this.callbackForm.get('contactNo').value
			}
			this.hdService.verify(verifyObj).subscribe(resp => {				
				if(resp.statusCode == "102"){ // user exists...create callback
					this.initHDCallBackAPI(verifyObj,resp);
				} else if(resp.statusCode == "103"){ // user doesnt exist...auto reg
					let regObj = {
						entityID: verifyObj.entityID,
						idNumber: verifyObj.idNumber,
						mobileNumber: verifyObj.mobileNumber,
						optionCode: "32187",
						name: this.global_name,
						surname: this.global_surname
					}
					this.hdService.registerHDUser(regObj).subscribe(regResp => {
						if(regResp.statusCode == "300"){ // unsuccessfull
							this.isLoading = false;
							this.errorMsg = `We could not register your profile due to a technical error. 
								Please contact us on +27 (0) 87 230 0002.`;
						} else if(regResp.statusCode == "200"){ // succesfull
							this.global_userID = regResp.userID;
							this.global_personID = regResp.personID;
							this.initHDCallBackAPI(verifyObj,regResp);
						}
					}, err => {
						this.errorMsg = `We could not log your call. Please contact us on +27 (0) 87 230 0002.`;
						this.isLoading = false;						
					})
				}
			}, err => {
				this.isLoading = false;
				this.errorMsg = 'We could not log your call. Please contact us on +27 (0) 87 230 0002.';
			});
		}
	}

}
