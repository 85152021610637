import { Component, Input, OnInit } from '@angular/core';
import { GlobalDataService } from '../../../shared/global-data';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	isMomWellness: boolean = false;
	isSars: boolean = false;
	year: number;
	pData: any = [];
	@Input() loggedOut = '';



	constructor( private globalService: GlobalDataService,
        private lsService: StorageMap) { }

	ngOnInit() {
		let isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');
		if(isSiteMomWellness){
			this.isMomWellness = true;
		}

		  /* lets get profile data */
		  this.globalService.profileData.subscribe(gsData => {
            this.lsService.get('profileData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
                    this.pData.push(lsData);
                } else {
                    if(gsData != null){
                        this.pData.push(gsData);
                    }                    
                }
            });
        });

		this.sarsCheck();
	}

	sarsCheck() {
		let str = localStorage.getItem('ParentCode');
		let ParentCode = str?.replace(/\s/g, '');
		if(this.isMomWellness && ParentCode == "60088"){
			this.isSars = false;
		}else if (ParentCode == "60088" && !this.isMomWellness) { //SARS -South African Revenue Service
			this.isSars = true;
		} else {
			this.isSars = false;
		}
	}


}
