import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GlobalDataService } from '../shared/global-data';
import { StorageMap } from '@ngx-pwa/local-storage';
import { reduce, share } from 'rxjs/operators';
import { QuestionnaireService } from '../shared/services/questionnare.service';
import { GoogleAnalyticsService } from "../shared/services/google-analytics-service.service";
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-suggested-resource',
    templateUrl: './suggested-resource.component.html',
    styleUrls: ['./suggested-resource.component.css']
})
export class SuggestedResourceComponent implements OnInit {

    score: string = "";
    scorecolor: string = "";
    scoretext: string = "";
    pdfURL: string;
    currTBlockStep: number;
    mainType: string = 'EAP';
    pData: any = [];
    isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');
    contentServer_MH = `https://content.momentum.co.za/EAP`;
    contentServer_MOM = `https://content.momentum.co.za/mmsa/complete_health/pdfs/Wellness/Reports`;
    currentCounter: number = 1;   

    constructor(private route: ActivatedRoute,
        private router: Router, private globalService: GlobalDataService, private googleAnalyticsService: GoogleAnalyticsService, 
        private lsService: StorageMap, private queServ:QuestionnaireService) { }

    ngOnInit(): void {

        // Tabs
        let that = this;
        $("body").on("click", ".tab", function(){
            let getIndex: any = $(this).attr('data-index');
            that.currentCounter = getIndex;
            that.currentCounter++;
            $(".tab-header .active").removeClass('active');
            $(this).addClass('active');
            $(".tcontent").removeClass('active');
            $(".tcontent").eq(getIndex).addClass('active');
        });

        /* lets get profile data */
        this.globalService.profileData.subscribe(gsData => {
            this.lsService.get('profileData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
                    this.pData.push(lsData);
                } else {
                    if(gsData != null){
                        this.pData.push(gsData);
                    }                    
                }
            });
        });
        this.getScore();
    }

    getScore() {
        var id = CryptoJS.AES.decrypt(localStorage.getItem("userIdNumber"), this.globalService.secretKey).toString(CryptoJS.enc.Utf8);
		let idnumber = id.toString(CryptoJS.enc.Utf8);
        this.queServ.getScoreQuestionnaire(idnumber).subscribe(result => {
            if(result !== undefined) {
                this.score = result[0].score;
                if (this.score <= "20") {
                    this.scorecolor = "Green"
                    this.scoretext="which suggests that you have a pretty good handle on what you need to do to stay healthy, excellent news!";
                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(GREEN)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Green-Report.pdf`;
                    }                    
                } else if (this.score <= "45") {
                    this.scorecolor = "Amber"
                    this.scoretext ="which suggests that there is some work to do on your current lifestyle. "
                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(AMBER)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Amber-Report.pdf`;
                    }                    
                } else {
                    this.scorecolor = "Red"
                    this.scoretext = "It’s important to start understanding what a healthy lifestyle is and what changes you can make to improve yours."                    
                    if(this.isSiteMomWellness){
                        this.pdfURL = `${this.contentServer_MOM}/Lifestyle_Programme_(RED)_MW.pdf`;
                    } else { // MH report
                        this.pdfURL = `${this.contentServer_MH}/Red-Report.pdf`;
                    }
                }
            }
        });
    }

    //Google Analytic
    eatwell(){
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Eat well - Post Login', 'Member MHWwellness Eat well - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Eat well - Post Login', 'Member MHWwellness Eat well - Post Login')
    }
    moveMore(){
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Move More - Post Login', 'Member MHWwellness Move More - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Move More - Post Login', 'Member MHWwellness Move More - Post Login');
    }
    makeSleep(){
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Make sleep a priority - Post Login', 'Member MHWwellness Make sleep a priority - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Make sleep a priority - Post Login', 'Member MHWwellness Make sleep a priority - Post Login');

    }
    avoidRisk(){
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Avoid risky behaviours  - Post Login', 'Member MHWwellness Avoid risky behaviours  - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Avoid risky behaviours  - Post Login', 'Member MHWwellness Avoid risky behaviours  - Post Login');
    }
    knowNumber(){
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Know your numbers - Post Login', 'Member MHWwellness Know your numbers  - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Know your numbers - Post Login', 'Member MHWwellness Know your numbers  - Post Login')
    }

     manageStress(){
            this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Manage your stress  - Post Login', 'Member MHWwellness Manage your stress  - Post Login');
            this.googleAnalyticsService.pushDirectEvent('Post Login Click', 'Member MHWwellness Manage your stress  - Post Login', 'Member MHWwellness Manage your stress  - Post Login');
    }
   
}